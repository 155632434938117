import styled from "@emotion/styled";
import { breakPoints } from "../../../../common/styles/media";

export const ContactWrapper = styled.section`
  width: 100%;
  height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  width: 100%;
  font-size: 50px;
  text-align: center;
  color: #111827;
  @media ${breakPoints.mobile} {
    font-size: 22px;
    font-weight: 700;
  }
`;

export const Tagline = styled.div`
  width: 100%;
  padding: 10px;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #6d7280;
  @media ${breakPoints.mobile} {
    font-size: 16px;
    width: 335px;
  }
`;

export const FormWrapper = styled.div`
  height: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

export const NameInput = styled.input`
  width: 520px;
  height: 50px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  padding: 10px;
  ::placeholder {
    opacity: 0.5;
  }
  @media ${breakPoints.mobile} {
    width: 320px;
  }
`;

export const EmailInput = styled.input`
  width: 520px;
  height: 50px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  padding: 10px;
  ::placeholder {
    opacity: 0.5;
  }
  @media ${breakPoints.mobile} {
    width: 320px;
  }
`;

export const MessageInput = styled.textarea`
  width: 520px;
  height: 130px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  padding: 10px;
  resize: vertical;
  ::placeholder {
    opacity: 0.5;
  }
  @media ${breakPoints.mobile} {
    width: 320px;
  }
`;

export const SendButton = styled.button`
  width: 226px;
  height: 50px;
  border-radius: 5px;
  background: linear-gradient(to left, #1e205f 50%, #3455cd 5%) right;
  color: #fff;
  background-size: 200% 100%;
  transition: all 0.2s ease-in-out;
  border: none;
  :hover {
    background-position: left;
    cursor: pointer;
  }
  @media ${breakPoints.mobile} {
    width: 156px;
    height: 40px;
  }
`;

export const ErrorMessage = styled.div`
  font-size: 10px;
  color: #ff0000;
  margin-top: -10px;
  @media ${breakPoints.mobile} {
    font-size: 8px;
  }
`;
