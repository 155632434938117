import { globalStyles } from "./common/styles/globalStyles";
import { Global } from "@emotion/react";
import Layout from "./common/layouts";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/home";
import { NavigationUtil } from "./utils/navigation";

function App() {
  return (
    <>
      <Global styles={globalStyles} />
      <Layout>
        <Routes>
          <Route path={NavigationUtil.home} element={<HomePage />} />
        </Routes>
      </Layout>
    </>
  );
}

export default App;
