import styled from "@emotion/styled";
import { breakPoints } from "../../../../common/styles/media";

// Platform Service Wrapper
export const PlatformWrapper = styled.section`
  width: 100%;
  height: 955px;
  background: #f7fbfe;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${breakPoints.mobile} {
    height: 1320px;
  }
`;

export const PlatformTitle = styled.h1`
  width: 590px;
  font-weight: 700;
  font-size: 50px;
  line-height: 130%;
  text-align: center;
  color: #000;
  padding: 30px 0px 20px 0px;
  @media ${breakPoints.mobile} {
    width: 335px;
    height: 78px;
    font-size: 22px;
    padding: 0px;
    margin-top: 20px;
  }
`;

export const PlatformBagoSpan = styled.span`
  color: #65a3ff;
`;

export const PlatformBuxiSpan = styled.span`
  color: #65a3ff;
  padding-right: 6px;
`;

export const PlatformTagline = styled.div`
  width: 660px;
  height: 54px;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #6d7280;
  @media ${breakPoints.mobile} {
    width: 335px;
    height: 120px;
    font-size: 16px;
    line-height: 155%;
  }
`;

export const PlatformContentsWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px 0px;
`;

export const PlatformContentFirstRow = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${breakPoints.mobile} {
    flex-direction: column;
  }
`;

export const GolfWrapper = styled.div`
  width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media ${breakPoints.mobile} {
    width: 100%;
  }
`;

export const AirWrapper = styled.div`
  width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media ${breakPoints.mobile} {
    width: 100%;
  }
`;

export const PlatformGolf = styled.div`
  width: 384px;
  height: 246px;
  background-image: url("images/golf.png");
  background-repeat: no-repeat;
  background-size: contain;
  @media ${breakPoints.mobile} {
    width: 335px;
    height: 230px;
  }
`;

export const PlatformAir = styled.div`
  width: 384px;
  height: 246px;
  background-image: url("images/air.png");
  background-repeat: no-repeat;
  background-size: contain;
  @media ${breakPoints.mobile} {
    width: 335px;
    height: 230px;
  }
`;

export const PlatformContentSecondRow = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${breakPoints.mobile} {
    flex-direction: column;
  }
`;

export const TripWrapper = styled.div`
  width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media ${breakPoints.mobile} {
    width: 100%;
  }
`;

export const WeddingWrapper = styled.div`
  width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media ${breakPoints.mobile} {
    width: 100%;
  }
`;

export const PlatformGolfContent = styled.div`
  width: 289px;
  height: 40px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #1c1c57;
  padding-top: 16px;
  @media ${breakPoints.mobile} {
    padding-top: 8px;
    height: 55px;
    color: #6d7280;
    font-size: 16px;
  }
`;

export const PlatformAirContent = styled.div`
  width: 360px;
  height: 40px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #1c1c57;
  padding-top: 16px;
  @media ${breakPoints.mobile} {
    width: 280px;
    padding-top: 8px;
    height: 55px;
    color: #6d7280;
    font-size: 16px;
  }
`;

export const PlatformWeddingContent = styled.div`
  width: 389px;
  height: 20px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #1c1c57;
  padding-top: 16px;
  @media ${breakPoints.mobile} {
    width: 289px;
    height: 65px;
    color: #6d7280;
    font-size: 16px;
  }
`;

export const PlatformTripContent = styled.div`
  width: 330px;
  height: 20px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #1c1c57;
  padding-top: 16px;
  @media ${breakPoints.mobile} {
    width: 289px;
    height: 65px;
    color: #6d7280;
    font-size: 16px;
  }
`;

export const PlatformTrip = styled.div`
  width: 384px;
  height: 246px;
  background-image: url("images/trip.png");
  background-repeat: no-repeat;
  background-size: contain;
  @media ${breakPoints.mobile} {
    width: 335px;
    height: 230px;
    background-size: cover;
  }
`;

export const PlatformWedding = styled.div`
  width: 384px;
  height: 246px;
  background-image: url("images/wedding.png");
  background-repeat: no-repeat;
  background-size: contain;
  @media ${breakPoints.mobile} {
    width: 335px;
    height: 230px;
    background-size: cover;
  }
`;

export const PlatformCardWrapper = styled.div`
  width: 384px;
  height: 246px;
  padding: 32px;
  background: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PlatformContent = styled.div`
  width: 320px;
  height: 30px;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: #111827;
  text-align: center;
`;

export const PlatformCardTagline = styled.div`
  width: 320px;
  height: 48px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #6d7280;
`;
// ---------------------------------------------------------------
