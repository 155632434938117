import styled from "@emotion/styled";
import { breakPoints } from "../../../../common/styles/media";

// T2E Platform Wrapper
export const T2EWrapper = styled.section`
  width: 100%;
  height: 1315px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0px 60px 0px;
`;

export const T2ETitle = styled.h1`
  width: 100%;
  height: 57px;
  line-height: 115%;
  text-align: center;
  font-size: 50px;
  padding: 20px 0px 120px 0px;
  @media ${breakPoints.mobile} {
    width: 335px;
    height: 36px;
    font-weight: 700;
    font-size: 22px;
    padding: 0px;
  }
`;

export const T2EBuxiSpan = styled.span`
  color: #65a3ff;
`;

export const T2EFirstCardWrapper = styled.section`
  display: flex;
  width: 1057px;
  height: 332px;
  gap: 136px;
  @media ${breakPoints.mobile} {
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 400px;
    gap: 0;
  }
`;

export const T2EContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const T2EBenefitContent = styled.div`
  width: 440px;
  height: 72px;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  color: #111827;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media ${breakPoints.mobile} {
    width: 100%;
    height: 56px;
    font-size: 16px;
    font-weight: 400;
  }
`;

export const T2EContent = styled.div`
  width: 433px;
  height: 72px;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  color: #111827;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media ${breakPoints.mobile} {
    width: 335px;
    height: 56px;
    font-size: 16px;
    font-weight: 400;
  }
`;

export const T2EContentSpan = styled.span`
  color: #65a3ff;
  margin-left: 8px;
  @media ${breakPoints.mobile} {
    color: #111827;
  }
`;

export const T2ETagline = styled.div`
  width: 433px;
  height: 72px;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #6d7280;
  @media ${breakPoints.mobile} {
    width: 335px;
    height: 92px;
    font-size: 16px;
    margin-bottom: 60px;
  }
`;

export const T2ESettleContent = styled.div`
  width: 384px;
  height: 72px;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  @media ${breakPoints.mobile} {
    width: 335px;
    height: 56px;
    font-size: 16px;
    font-weight: 400;
  }
`;

export const T2ESettleTagline = styled.div`
  width: 384px;
  height: 72px;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #6d7280;
  margin-top: 14px;
  @media ${breakPoints.mobile} {
    width: 335px;
    font-size: 16px;
    margin-top: 2px;
  }
`;

export const Img = styled.img`
  width: 488px;
  height: 332px;
  background: #d3d3d3;
  border-radius: 6px;
  @media ${breakPoints.mobile} {
    width: 335px;
    height: 260px;
  }
`;

export const AirplaneImg = styled.img`
  width: 488px;
  height: 332px;
  background: #d3d3d3;
  border-radius: 6px;
  @media ${breakPoints.mobile} {
    width: 335px;
    height: 260px;
  }
`;

export const T2ESecondCardWrapper = styled.section`
  display: flex;
  width: 1057px;
  height: 332px;
  gap: 136px;
  @media ${breakPoints.mobile} {
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
    height: 400px;
    gap: 0;
  }
`;

export const T2EThirdCardWrapper = styled.section`
  display: flex;
  width: 1057px;
  height: 332px;
  gap: 136px;
  @media ${breakPoints.mobile} {
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 400px;
    gap: 0;
  }
`;
// ---------------------------------------------------------------
