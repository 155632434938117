import * as S from "./faq-css";
import { Collapse } from "antd";
import { useTranslation } from "react-i18next";

export default function FAQ() {
  const { Panel } = Collapse;
  const { t } = useTranslation();

  const answer1 = t("faq.answer1");

  const answer2 = t("faq.answer2");

  const answer3 = t("faq.answer3");

  const answer4 = t("faq.answer4");

  return (
    <S.FaqWrapper>
      <S.FaqTitle id="faq">FAQ</S.FaqTitle>
      <S.FaqTagline>{t("faq.tag")}</S.FaqTagline>
      <S.QuestionsWrapper>
        <S.Collapses accordion expandIconPosition="right">
          <Panel
            header={t("faq.q1")}
            key="1"
            style={{
              fontWeight: 400,
              fontSize: 15,
              lineHeight: "150%",
              color: "#111827",
            }}
          >
            <p
              style={{
                fontWeight: 400,
                fontSize: 15,
                color: "#6D7280",
              }}
            >
              {answer1}
            </p>
          </Panel>
          <Panel
            header={t("faq.q2")}
            key="2"
            style={{
              fontWeight: 400,
              fontSize: 15,
              lineHeight: "150%",
              color: "#111827",
            }}
          >
            <p
              style={{
                fontWeight: 400,
                fontSize: 15,
                color: "#6D7280",
              }}
            >
              {answer2}
            </p>
          </Panel>
          <Panel
            header={t("faq.q3")}
            key="3"
            style={{
              fontWeight: 400,
              fontSize: 15,
              lineHeight: "150%",
              color: "#111827",
            }}
          >
            <p
              style={{
                fontWeight: 400,
                fontSize: 15,
                color: "#6D7280",
              }}
            >
              {answer3}
            </p>
          </Panel>
          <Panel
            header={t("faq.q4")}
            key="4"
            style={{
              fontWeight: 400,
              fontSize: 15,
              lineHeight: "150%",
              color: "#111827",
            }}
          >
            <p
              style={{
                fontWeight: 400,
                fontSize: 15,
                color: "#6D7280",
              }}
            >
              {answer4}
            </p>
          </Panel>
        </S.Collapses>
      </S.QuestionsWrapper>
    </S.FaqWrapper>
  );
}
