import styled from "@emotion/styled";
import { breakPoints } from "../../../../common/styles/media";

// Reward Platform Wrapper
export const SecondWrapper = styled.section`
  width: 100%;
  height: 852px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;
  padding-top: 80px;
  @media ${breakPoints.mobile} {
    width: 100%;
    height: 400px;
    padding-top: 40px;
  }
`;

export const SecondContentWrapper = styled.div`
  width: 590px;
  height: 178px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  @media ${breakPoints.mobile} {
    width: 100%;
  }
`;

export const SecondContent = styled.h1`
  width: 590px;
  height: 114px;
  font-weight: 700;
  font-size: 50px;
  line-height: 115%;
  text-align: center;
  color: #111827;
  @media ${breakPoints.mobile} {
    width: 100%;
    height: 77px;
    font-size: 22px;
  }
`;

export const ContentBold = styled.span`
  font-weight: 700;
  @media ${breakPoints.mobile} {
    font-size: 22px;
  }
`;

export const SecondTagline = styled.h2`
  width: 590px;
  height: 48px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #6d7280;
  @media ${breakPoints.mobile} {
    width: 355px;
    height: 76px;
    font-size: 16px;
  }
`;

export const SecondImgWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${breakPoints.mobile} {
    width: 341px;
    height: 118px;
  }
`;

export const GlobalImg = styled.img`
  width: 100%;
  height: 100%;
  @media ${breakPoints.mobile} {
    height: 118px;
  }
`;
// ---------------------------------------------------------------
