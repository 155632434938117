import * as yup from "yup";

export const schema = yup.object().shape({
  name: yup.string().required("please enter your name"),
  email: yup.string().email().required("please correctly enter your email"),
  message: yup
    .string()
    .min(1, "please enter more than 1 word")
    .required("please enter a message"),
});
