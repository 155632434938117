import styled from "@emotion/styled";
import { breakPoints } from "../../../../common/styles/media";

// 1st Wrapper
export const FirstWrapper = styled.section`
  display: flex;
  width: 100%;
  height: 520px;
  background: linear-gradient(90deg, #1e205f -3.4%, #3455cd 100%);
  @media ${breakPoints.mobile} {
    height: 680px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const LeftSection = styled.section`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${breakPoints.mobile} {
    width: 100%;
    height: 50%;
  }
`;

export const ContentWrapper = styled.div`
  width: 503px;
  height: 400px;
  @media ${breakPoints.mobile} {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const Content = styled.h1`
  width: 503px;
  height: 330px;
  color: #fff;
  font-size: 56px;
  font-weight: 600px;
  line-height: 130%;
  @media ${breakPoints.mobile} {
    width: 335px;
    height: 170px;
    font-size: 32px;
  }
`;

export const BuxiSpan = styled.span`
  color: #65a3ff;
  padding-left: 6px;
`;

export const Tagline = styled.h2`
  width: 503px;
  height: 48px;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.2px;
  color: rgba(255, 255, 255, 0.51);
  @media ${breakPoints.mobile} {
    width: 335px;
    height: 100px;
    padding-top: 10px;
  }
`;

export const RightSection = styled.section`
  width: 50%;
  display: flex;
  align-items: center;
  @media ${breakPoints.mobile} {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const MainImg = styled.img`
  width: 595px;
  height: 454px;
  @media ${breakPoints.mobile} {
    height: 335px;
  }
`;
// ---------------------------------------------------------------
