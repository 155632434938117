import styled from "@emotion/styled";
import { Collapse } from "antd";
import { breakPoints } from "../../../../common/styles/media";

// FAQ Wrapper
export const FaqWrapper = styled.section`
  width: 100%;
  height: 602px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${breakPoints.mobile} {
    height: 440px;
  }
`;

export const FaqTitle = styled.h1`
  width: 590px;
  height: 70px;
  font-weight: 700;
  font-size: 50px;
  line-height: 140%;
  text-align: center;
  color: #111827;
  padding: 80px;
  @media ${breakPoints.mobile} {
    width: 80px;
    height: 40px;
    font-size: 22px;
    padding: 0px;
  }
`;

export const FaqTagline = styled.div`
  width: 590px;
  height: 48px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #6d7280;
  @media ${breakPoints.mobile} {
    width: 323px;
    height: 60px;
    padding-top: 5px;
    font-size: 16px;
  }
`;

export const QuestionsWrapper = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${breakPoints.mobile} {
    height: 326px;
    padding: 30px;
  }
`;

export const Collapses = styled(Collapse)`
  width: 800px;
  background: #f3f4f6;
`;
