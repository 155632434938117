import styled from "@emotion/styled";
import { breakPoints } from "../../styles/media";

interface HeaderProps {
  isShow?: boolean;
}

export const Wrapper = styled.nav`
  width: 100%;
  height: 91px;
  background: linear-gradient(90deg, #1e205f -3.4%, #3455cd 100%);
  display: flex;
  align-items: center;
  @media ${breakPoints.mobile} {
    background: ${(props: HeaderProps) =>
      props.isShow
        ? "#fff"
        : "linear-gradient(90deg, #1e205f -3.4%, #3455cd 100%)"};
    border-bottom: ${(props) => (props.isShow ? "1px solid #cfcfcf" : "none")};
  }
`;

export const DeadSpace = styled.div`
  width: 146px;
  height: 100%;
  @media (max-width: 1400px) {
    display: none;
  }
`;

export const LogoWrapper = styled.div`
  width: 55%;
  height: 100%;
  display: flex;
  align-items: center;
  @media ${breakPoints.mobile} {
    width: 46%;
    justify-content: center;
  }
`;

export const ImgWrapper = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media ${breakPoints.mobile} {
    width: 100%;
  }
`;

export const LogoImg = styled.img`
  width: 150px;
  height: 45px;
  :hover {
    cursor: pointer;
  }
  @media ${breakPoints.mobile} {
    width: 130px;
    height: 38px;
  }
`;

export const NavWrapper = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  @media ${breakPoints.mobile} {
    visibility: hidden;
  }
`;

export const MainLabel = styled.a`
  color: rgba(255, 255, 255, 0.51);
  font-weight: 600;
  line-height: 130%;
  font-size: 14px;
  text-decoration: none;
  :hover {
    color: #fff;
    cursor: pointer;
  }
`;

export const AboutLabel = styled.a`
  color: rgba(255, 255, 255, 0.51);
  font-weight: 600;
  line-height: 130%;
  font-size: 14px;
  text-decoration: none;
  :hover {
    color: #fff;
    cursor: pointer;
  }
`;

export const PartnersLabel = styled.a`
  color: rgba(255, 255, 255, 0.51);
  font-weight: 600;
  line-height: 130%;
  font-size: 14px;
  text-decoration: none;
  :hover {
    color: #fff;
    cursor: pointer;
  }
`;

export const FaqLabel = styled.a`
  color: rgba(255, 255, 255, 0.51);
  font-weight: 600;
  line-height: 130%;
  font-size: 14px;
  text-decoration: none;
  :hover {
    color: #fff;
    cursor: pointer;
  }
`;

export const ContactLabel = styled.a`
  color: rgba(255, 255, 255, 0.51);
  font-weight: 600;
  line-height: 130%;
  font-size: 14px;
  text-decoration: none;
  :hover {
    color: #fff;
    cursor: pointer;
  }
`;

export const WhitepaperLabel = styled.a`
  color: rgba(255, 255, 255, 0.51);
  font-weight: 600;
  line-height: 130%;
  font-size: 14px;
  text-decoration: none;
  :hover {
    color: #fff;
    cursor: pointer;
  }
`;

export const LanguageWrapper = styled.div`
  width: 120px;
  display: flex;
  justify-content: flex-end;
`;

export const EngLabel = styled.label`
  color: rgba(255, 255, 255, 0.51);
  font-weight: 600;
  line-height: 130%;
  font-size: 14px;
  padding-right: 20px;
  :hover {
    color: #00cdfc;
    cursor: pointer;
  }
`;

export const KorLabel = styled.label`
  color: rgba(255, 255, 255, 0.51);
  font-weight: 600;
  line-height: 130%;
  font-size: 14px;
  :hover {
    color: #00cdfc;
    cursor: pointer;
  }
`;

// ----------Mobile CSS----------

export const Menu = styled.img`
  visibility: hidden;
  @media ${breakPoints.mobile} {
    visibility: visible;
    cursor: pointer;
  }
`;

export const MobileLogoImg = styled.img`
  display: none;
  @media ${breakPoints.mobile} {
    display: block;
    cursor: pointer;
    width: 130px;
    height: 38px;
    :hover {
      cursor: pointer;
    }
  }
`;

export const MobileXImg = styled.img`
  display: none;
  @media ${breakPoints.mobile} {
    display: block;
    cursor: pointer;
  }
`;

export const MobileMenuWrapper = styled.div`
  display: none;
  @media ${breakPoints.mobile} {
    display: block;
    width: 100%;
    height: 680px;
    background: #fff;
    position: sticky;
    top: 0px;
    z-index: 1;
    display: flex;
    flex-direction: column;
  }
`;

export const MobileMain = styled.a`
  cursor: pointer;
  width: 120px;
  color: #1c1c57;
  text-decoration: none;
  :hover {
    opacity: 0.5;
  }
`;

export const MobileAbout = styled.a`
  cursor: pointer;
  width: 120px;
  color: #1c1c57;
  text-decoration: none;
  :hover {
    opacity: 0.5;
  }
`;

export const MobilePartners = styled.a`
  cursor: pointer;
  width: 120px;
  color: #1c1c57;
  text-decoration: none;
  :hover {
    opacity: 0.5;
  }
`;

export const MobileFaq = styled.a`
  cursor: pointer;
  width: 120px;
  color: #1c1c57;
  text-decoration: none;
  :hover {
    opacity: 0.5;
  }
`;

export const MobileContact = styled.a`
  cursor: pointer;
  width: 120px;
  color: #1c1c57;
  text-decoration: none;
  :hover {
    opacity: 0.5;
  }
`;

export const MobileWhitePaper = styled.a`
  cursor: pointer;
  width: 120px;
  color: #1c1c57;
  text-decoration: none;
  :hover {
    opacity: 0.5;
  }
`;

export const MobileNavWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 30px;
  width: 100%;
  height: 250px;
  @media ${breakPoints.mobile} {
    padding: 0px;
    width: 65%;
    align-items: center;
    margin-top: 20px;
  }
`;

export const MobileSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 30px;
  height: 60px;
  color: #1c1c57;
  @media ${breakPoints.mobile} {
    padding: 0px;
    width: 65%;
    justify-content: center;
  }
`;

export const MobileLangWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 100px;
  padding: 30px;
  @media ${breakPoints.mobile} {
    padding: 0px;
    width: 65%;
    align-items: center;
  }
`;

export const EngLangLabel = styled.label`
  width: 120px;
  cursor: pointer;
  color: #1c1c57;
  :hover {
    opacity: 0.5;
  }
`;

export const KorLangLabel = styled.label`
  width: 120px;
  margin-top: 14px;
  color: #1c1c57;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

export const Globe = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;
