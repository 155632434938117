import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { breakPoints } from "../../../../common/styles/media";

// Reward Point exchange Wrapper
export const ThirdWrapper = styled.section`
  width: 100%;
  height: 800px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  background: #fff;
  @media ${breakPoints.mobile} {
    height: 441px;
    padding-top: 20px;
  }
`;

export const ThirdContentWrapper = styled.div`
  width: 884px;
  height: 198px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  @media ${breakPoints.mobile} {
    width: 100%;
    height: 160px;
  }
`;

export const ThirdContent = styled.h1`
  width: 590px;
  height: 114px;
  font-weight: 700;
  font-size: 50px;
  line-height: 115%;
  text-align: center;
  color: #111827;
  @media ${breakPoints.mobile} {
    width: 333px;
    height: 68px;
    font-size: 22px;
  }
`;

export const ContentBold = styled.span`
  font-weight: 700;
  @media ${breakPoints.mobile} {
    font-size: 22px;
  }
`;

export const ThirdTagline = styled.h2`
  width: 590px;
  height: 48px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #6d7280;
  @media ${breakPoints.mobile} {
    width: 335px;
    height: 77px;
    font-size: 16px;
  }
`;

export const ImgWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  @media ${breakPoints.mobile} {
    height: 255px;
  }
`;

export const LeftWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 50%;
  height: 100%;
`;

const Bounce = keyframes`
    from {
        transform: translatex(0px) translatey(0px);
        opacity: 0;
    }
    to {
        transform: translatex(53px) translatey(-44px);
        opacity: 1;
    }
`;

const MobileBounce = keyframes`
    from {
      transform: translatex(0px) translatey(0px);
      opacity: 0;
    }
    to {
      transform: translatex(23px) translatey(-18px);
      opacity: 1;
    }
`;

export const PlaneImg = styled.img`
  width: 138px;
  height: 122px;
  position: relative;
  z-index: 1;
  top: 44px;
  left: 60px;
  transform: rotate(-5deg);
  animation: ${Bounce} 4s infinite;
  @media ${breakPoints.mobile} {
    width: 42px;
    height: 42px;
    top: 25px;
    left: 25px;
    animation: ${MobileBounce} 4s infinite;
  }
`;

export const PhoneImg = styled.img`
  position: relative;
  @media ${breakPoints.mobile} {
    width: 135px;
    height: 241px;
  }
`;

export const RightWrapper = styled.div`
  width: 50%;
  height: 100%;
  @media ${breakPoints.mobile} {
    overflow: hidden;
  }
`;

export const WalletImg = styled.img`
  position: relative;
  @media ${breakPoints.mobile} {
    width: 135px;
    height: 241px;
  }
`;

const Shop = keyframes`
    0% {
        transform: rotate(-10deg);
    }
    50% {
        transform: rotate(40deg);
    }
    100% {
        transform: rotate(-10deg);
    }
`;

export const ShopImg = styled.img`
  width: 87px;
  height: 85px;
  position: relative;
  z-index: 1;
  bottom: 368px;
  right: 38px;
  animation: ${Shop} 3s ease-out infinite;
  @media ${breakPoints.mobile} {
    width: 31px;
    height: 31px;
    bottom: 172px;
    right: 22px;
  }
`;

const Car = keyframes`
    from {
        right: 110px;
    }
    to {
        right: 70px;
    }
`;

const MobileCar = keyframes`
  from {
    right: 50px;
  }
  to {
    right: 30px;
  }
`;

const MobileSmallCar = keyframes`
  from {
    left: 115px;
  }
  to {
    left: 130px;
  }
`;

export const CarImg = styled.img`
  width: 82px;
  height: 76px;
  position: relative;
  z-index: 1;
  bottom: 65px;
  right: 110px;
  animation: ${Car} 2s infinite;
  @media ${breakPoints.mobile} {
    width: 37px;
    height: 34px;
    bottom: 30px;
    right: 50px;
    animation: ${MobileCar} 2s infinite;
  }
  @media screen and (max-width: 406px) {
    bottom: 68px;
    right: 0px;
    left: 115px;
    animation: ${MobileSmallCar} 2s infinite;
  }
`;

// ---------------------------------------------------------------
