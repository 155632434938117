import styled from "@emotion/styled";
import { breakPoints } from "../../../../common/styles/media";

// Partners Wrapper
export const PartnersWrapper = styled.section`
  width: 100%;
  height: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(241, 241, 241, 0.4);

  .slick-slide > div {
    width: 80%;
    margin: auto;
    height: 120px;
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    border: 1px solid #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
    @media ${breakPoints.mobile} {
      border: none;
      box-shadow: none;
      background-color: #fff;
      height: 100px;
    }
  }
  @media ${breakPoints.mobile} {
    height: 380px;
    background: #fff;
  }
`;

export const PartnersTitle = styled.h1`
  width: 590px;
  height: 57px;
  font-weight: 700;
  font-size: 50px;
  line-height: 115%;
  text-align: center;
  color: #000;
  padding: 40px 0px 70px 0px;
  @media ${breakPoints.mobile} {
    width: 157px;
    font-size: 22px;
    padding: 0px;
    margin-top: 20px;
  }
`;

export const PartnersBuxiSpan = styled.span`
  color: #65a3ff;
  @media ${breakPoints.mobile} {
    font-size: 20px;
  }
`;

export const PartnersTagline = styled.div`
  width: 590px;
  height: 24px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #6d7280;
  @media ${breakPoints.mobile} {
    font-size: 16px;
    height: 42px;
  }
`;

export const BuxiImg = styled.img`
  width: 50%;
  margin: auto;
  @media ${breakPoints.mobile} {
    width: 80%;
  }
`;

export const AnswerImg = styled.img`
  width: 80%;
  margin: auto;
  @media ${breakPoints.mobile} {
    width: 100%;
  }
`;

export const LotteCardImg = styled.img`
  width: 100%;
  margin: auto;
  @media ${breakPoints.mobile} {
    width: 100%;
  }
`;

export const VisaImg = styled.img`
  width: 55%;
  margin: auto;
  @media ${breakPoints.mobile} {
    width: 70%;
  }
`;

export const NaverImg = styled.img`
  width: 80%;
  margin: auto;
  @media ${breakPoints.mobile} {
    width: 100%;
  }
`;

export const DutyFree = styled.img`
  width: 80%;
  margin: auto;
  @media ${breakPoints.mobile} {
    width: 100%;
  }
`;

export const LotteRentalImg = styled.img`
  width: 75%;
  margin: auto;
  @media ${breakPoints.mobile} {
    width: 100%;
  }
`;

export const Toss = styled.img`
  width: 170px;
  height: 63px;
  @media ${breakPoints.mobile} {
    width: 55px;
    height: 21px;
  }
`;

export const Kkday = styled.img`
  width: 55%;
  margin: auto;
  @media ${breakPoints.mobile} {
    width: 85%;
  }
`;

export const Hyundai = styled.img`
  width: 80%;
  margin: auto;
  @media ${breakPoints.mobile} {
    width: 100%;
  }
`;

export const Asiana = styled.img`
  width: 75%;
  margin: auto;
  @media ${breakPoints.mobile} {
    width: 100%;
  }
`;

export const KdImg = styled.img`
  width: 80%;
  margin: auto;
  @media ${breakPoints.mobile} {
    width: 100%;
  }
`;

export const Aero = styled.img`
  width: 75%;
  margin: auto;
  @media ${breakPoints.mobile} {
    width: 100%;
  }
`;

export const ZetcabImg = styled.img`
  width: 50%;
  margin: auto;
  @media ${breakPoints.mobile} {
    width: 70%;
  }
`;

export const PaymentWall = styled.img`
  width: 80%;
  margin: auto;
  @media ${breakPoints.mobile} {
    width: 95%;
  }
`;

export const LPoint = styled.img`
  width: 75%;
  margin: auto;
  @media ${breakPoints.mobile} {
    width: 95%;
  }
`;

export const VcarImg = styled.img`
  width: 85%;
  margin: auto;
  @media ${breakPoints.mobile} {
    width: 100%;
  }
`;

export const AspireImg = styled.img`
  width: 80%;
  margin: auto;
  @media ${breakPoints.mobile} {
    width: 100%;
  }
`;

export const ForParentsImg = styled.img`
  width: 100%;
  margin: auto;
  @media ${breakPoints.mobile} {
    width: 100%;
  }
`;

export const NkgenImg = styled.img`
  width: 100%;
  margin: auto;
  @media ${breakPoints.mobile} {
    width: 100%;
  }
`;

export const MkImg = styled.img`
  width: 45%;
  margin: auto;
  @media ${breakPoints.mobile} {
    width: 60%;
  }
`;

export const Shinyoung = styled.img`
  width: 80%;
  margin: auto;
  @media ${breakPoints.mobile} {
    width: 100%;
  }
`;

export const ImageHidden = styled.label`
  color: #fff;
`;

// ---------------------------------------------------------------
