import * as S from "./services-css";
import { useTranslation } from "react-i18next";

export default function Services() {
  const { t } = useTranslation();

  return (
    <S.PlatformWrapper>
      <S.PlatformTitle>
        <S.PlatformBagoSpan>bago&apos;s partner</S.PlatformBagoSpan> <br />
        <S.PlatformBuxiSpan>{t("service.title")}</S.PlatformBuxiSpan>
        {t("service.platform")}
      </S.PlatformTitle>
      <S.PlatformTagline>
        {t("service.intro")} {t("service.offer")}
      </S.PlatformTagline>
      <S.PlatformContentsWrapper>
        <S.PlatformContentFirstRow>
          <S.GolfWrapper>
            <S.PlatformGolf />
            <S.PlatformGolfContent>{t("service.golf")}</S.PlatformGolfContent>
          </S.GolfWrapper>
          <S.AirWrapper>
            <S.PlatformAir />
            <S.PlatformAirContent>{t("service.air")}</S.PlatformAirContent>
          </S.AirWrapper>
        </S.PlatformContentFirstRow>
        <S.PlatformContentSecondRow>
          <S.TripWrapper>
            <S.PlatformTrip />
            <S.PlatformTripContent>{t("service.trip")}</S.PlatformTripContent>
          </S.TripWrapper>
          <S.WeddingWrapper>
            <S.PlatformWedding />
            <S.PlatformWeddingContent>
              {t("service.wedding")}
            </S.PlatformWeddingContent>
          </S.WeddingWrapper>
        </S.PlatformContentSecondRow>
      </S.PlatformContentsWrapper>
    </S.PlatformWrapper>
  );
}
