import * as S from "./mining-css";
import { useTranslation } from "react-i18next";

export default function Mining() {
  const { t } = useTranslation();
  return (
    <S.MiningWrapper>
      <S.MiningTitle>{t("mining.title")}</S.MiningTitle>
      <S.MiningContentsWrapper>
        <S.FirstColumn>
          <S.MiningTopLeftPortion>
            <S.MiningFirstContent>{t("mining.staking")}</S.MiningFirstContent>
            <S.MiningFirstTagline>{t("mining.proceed")}</S.MiningFirstTagline>
          </S.MiningTopLeftPortion>
          <S.MiningFirstHalfEco>
            <S.MiningSecondContent>
              {t("mining.contribute")}
            </S.MiningSecondContent>
            <S.MiningSecondTagline>{t("mining.reward")}</S.MiningSecondTagline>
          </S.MiningFirstHalfEco>
        </S.FirstColumn>
        <S.MiningSecondPortion>
          <S.MiningImg src="images/mining.png" />
        </S.MiningSecondPortion>
        <S.SecondColumn>
          <S.MiningTopRightPortion>
            <S.MiningThirdContent>{t("mining.transport")}</S.MiningThirdContent>
            <S.MiningThirdTagline>{t("mining.display")}</S.MiningThirdTagline>
          </S.MiningTopRightPortion>
          <S.MiningFirstHalfPortion>
            <S.MiningFourthContent>{t("mining.other")}</S.MiningFourthContent>
            <S.MiningFourthTagline>
              {t("mining.excavate")}
            </S.MiningFourthTagline>
          </S.MiningFirstHalfPortion>
        </S.SecondColumn>
      </S.MiningContentsWrapper>
      <S.MiningMobileWrapper>
        <S.MobileFirstTagline>
          {t("mining.mine")} <br />
          {t("mining.deposite")}
        </S.MobileFirstTagline>

        <S.MobileSecondTagline>
          {t("mining.participate")} <br />
          {t("mining.ad")}
        </S.MobileSecondTagline>

        <S.MobileThirdTagline>
          {t("mining.eco")} <br /> {t("mining.develop")}
        </S.MobileThirdTagline>

        <S.MobileFourthTagline>
          {t("mining.part")} <br />
          {t("mining.project")}
        </S.MobileFourthTagline>
      </S.MiningMobileWrapper>
    </S.MiningWrapper>
  );
}
