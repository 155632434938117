import styled from "@emotion/styled";
import { breakPoints } from "../../../../common/styles/media";

// Token Mining wrapper
export const MiningWrapper = styled.section`
  width: 100%;
  height: 680px;
  background: #1c1c57;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${breakPoints.mobile} {
    height: 100%;
  }
`;

export const MiningTitle = styled.h1`
  width: 469px;
  height: 50px;
  font-weight: 700;
  font-size: 50px;
  line-height: 100%;
  color: #fff;
  gap: 80px;
  padding: 80px 0px;
  text-align: center;
  @media ${breakPoints.mobile} {
    width: 335px;
    font-size: 22px;
    gap: 0px;
    padding: 14px 0px;
  }
`;

export const MiningContentsWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  @media ${breakPoints.mobile} {
    flex-direction: column;
  }
`;

export const MiningFirstContent = styled.div`
  width: 256px;
  height: 50px;
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #fff;
  text-align: right;
`;

export const MiningSecondContent = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #fff;
  text-align: right;
`;

export const MiningThirdContent = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #fff;
  text-align: left;
`;

export const MiningFourthContent = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #fff;
  text-align: left;
`;

export const MiningFirstTagline = styled.div`
  width: 214px;
  height: 38px;
  font-weight: 400;
  font-size: 15px;
  line-height: 130%;
  text-align: right;
  color: #aeaeae;
`;

export const MiningSecondTagline = styled.div`
  width: 230px;
  height: 114px;
  font-weight: 400;
  font-size: 15px;
  line-height: 130%;
  text-align: right;
  color: #aeaeae;
  margin-top: 10px;
`;

export const MiningThirdTagline = styled.div`
  width: 214px;
  height: 57px;
  font-weight: 400;
  font-size: 15px;
  line-height: 130%;
  text-align: left;
  color: #aeaeae;
  margin-top: 10px;
`;

export const MiningFourthTagline = styled.div`
  width: 214px;
  height: 57px;
  font-weight: 400;
  font-size: 15px;
  line-height: 130%;
  text-align: left;
  color: #aeaeae;
  margin-top: 10px;
`;

export const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  @media ${breakPoints.mobile} {
    display: none;
  }
`;

export const MiningTopLeftPortion = styled.div`
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`;

export const MiningTopRightPortion = styled.div`
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const MiningFirstHalfPortion = styled.div`
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const MiningFirstHalfEco = styled.div`
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const MiningSecondPortion = styled.div`
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${breakPoints.mobile} {
    width: 100%;
    align-items: flex-start;
    padding-top: 40px;
  }
`;

export const MiningImg = styled.img`
  width: 488px;
  height: 332px;
  background: #d3d3d3;
  border-radius: 6px;
  @media ${breakPoints.mobile} {
    width: 335px;
    height: 226px;
  }
`;

export const MiningThirdPortion = styled.div`
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SecondColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  @media ${breakPoints.mobile} {
    display: none;
  }
`;

export const MiningMobileWrapper = styled.section`
  display: none;
  @media ${breakPoints.mobile} {
    display: block;
    font-weight: 700;
    font-size: 15px;
    color: #fff;
    line-height: 125%;
  }
`;

export const MobileFirstTagline = styled.div`
  padding: 20px;
  line-height: 150%;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
`;

export const MobileSecondTagline = styled.div`
  padding: 10px 20px 20px 20px;
  line-height: 150%;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
`;

export const MobileThirdTagline = styled.div`
  padding: 10px 20px 20px 20px;
  line-height: 150%;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
`;

export const MobileFourthTagline = styled.div`
  padding: 10px 20px 20px 20px;
  line-height: 150%;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
`;
// ---------------------------------------------------------------
