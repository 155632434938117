import * as S from "./blockchain-css";
import { useTranslation } from "react-i18next";

export default function Blockchain() {
  const { t } = useTranslation();

  return (
    <S.SecondWrapper id="about">
      <S.SecondContentWrapper>
        <S.SecondContent>
          {t("blockchain.blockchainContent")} <br />
          <S.ContentBold>
            {t("blockchain.blockchainContentTitle")}
          </S.ContentBold>
        </S.SecondContent>
        <S.SecondTagline>{t("blockchain.blockchainTagline")}</S.SecondTagline>
      </S.SecondContentWrapper>
      <S.SecondImgWrapper>
        <S.GlobalImg src="images/globe.png" />
      </S.SecondImgWrapper>
    </S.SecondWrapper>
  );
}
