import styled from "@emotion/styled";
import { breakPoints } from "../../styles/media";

export const Wrapper = styled.footer`
  width: 100%;
  height: 269px;
  background: #1c1c57;
  padding: 80px 112px;
  @media ${breakPoints.mobile} {
    height: 140px;
    padding: 0px;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.15);
`;

export const Terms = styled.div`
  height: 400px;
  word-break: keep-all;
  overflow-wrap: break-word;
  overflow-y: scroll;
`;

export const NavWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  @media ${breakPoints.mobile} {
    height: 70%;
  }
`;

export const ImgWrapper = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media ${breakPoints.mobile} {
    width: 45%;
    justify-content: flex-end;
  }
`;

export const LogoImg = styled.img`
  width: 130px;
  height: 39px;
  margin-left: 20px;
  :hover {
    cursor: pointer;
  }
`;

export const ContentWrapper = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media ${breakPoints.mobile} {
    visibility: hidden;
    width: 0%;
  }
`;

// not using anymore
export const MainLabel = styled.label`
  color: rgba(255, 255, 255, 0.51);
  font-weight: 600;
  line-height: 130%;
  font-size: 14px;
  :hover {
    color: #fff;
    cursor: pointer;
  }
`;

// not using anymore
export const AboutLabel = styled.label`
  color: rgba(255, 255, 255, 0.51);
  font-weight: 600;
  line-height: 130%;
  font-size: 14px;
  :hover {
    color: #fff;
    cursor: pointer;
  }
`;

// not using anymore
export const PartnersLabel = styled.label`
  color: rgba(255, 255, 255, 0.51);
  font-weight: 600;
  line-height: 130%;
  font-size: 14px;
  :hover {
    color: #fff;
    cursor: pointer;
  }
`;

// not using anymore
export const FaqLabel = styled.label`
  color: rgba(255, 255, 255, 0.51);
  font-weight: 600;
  line-height: 130%;
  font-size: 14px;
  :hover {
    color: #fff;
    cursor: pointer;
  }
`;

// not using anymore
export const ContactLabel = styled.label`
  color: rgba(255, 255, 255, 0.51);
  font-weight: 600;
  line-height: 130%;
  font-size: 14px;
  :hover {
    color: #fff;
    cursor: pointer;
  }
`;

export const TermsLabel = styled.a`
  color: rgba(255, 255, 255, 0.51);
  font-weight: 600;
  line-height: 130%;
  font-size: 14px;
  padding-right: 40px;
  text-decoration: none;
  :hover {
    color: #fff;
    cursor: pointer;
  }
`;

export const PrivacyLabel = styled.a`
  color: rgba(255, 255, 255, 0.51);
  font-weight: 600;
  line-height: 130%;
  font-size: 14px;
  text-decoration: none;
  :hover {
    color: #fff;
    cursor: pointer;
  }
`;

export const SocialWrapper = styled.div`
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${breakPoints.mobile} {
    width: 40%;
    justify-content: center;
  }
`;

export const NaverWrapper = styled.a`
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 2px;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
`;

export const FacebookWrapper = styled.a`
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
`;

export const InstaWrapper = styled.a`
  width: 22px;
  height: 22px;
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
`;

export const FaceLogo = styled.img`
  width: 8px;
  height: 10px;
`;

export const NaverLogo = styled.img`
  width: 8px;
  height: 8px;
`;

export const InstaLogo = styled.img`
  width: 20px;
  height: 20px;
`;

export const ReservedLabel = styled.div`
  width: 100%;
  height: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  color: rgba(255, 255, 255, 0.3);
  text-align: center;
`;
