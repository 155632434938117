import * as S from "./exchange-css";
import { useTranslation } from "react-i18next";

export default function Exchange() {
  const { t } = useTranslation();

  return (
    <S.ThirdWrapper>
      <S.ThirdContentWrapper>
        <S.ThirdContent>
          {t("exchange.worldwide")} <br />
          <S.ContentBold>{t("exchange.reward")}</S.ContentBold>
        </S.ThirdContent>
        <S.ThirdTagline>{t("exchange.points")}</S.ThirdTagline>
      </S.ThirdContentWrapper>
      <S.ImgWrapper>
        <S.LeftWrapper>
          <S.PlaneImg src="images/exchange/plane.png" />
          <S.PhoneImg src="images/exchange/logoPhone.png" />
        </S.LeftWrapper>
        <S.RightWrapper>
          <S.WalletImg src="images/exchange/wallet.png" />
          <S.ShopImg src="images/exchange/shop.png" />
          <S.CarImg src="images/exchange/car.png" />
        </S.RightWrapper>
      </S.ImgWrapper>
    </S.ThirdWrapper>
  );
}
