import * as S from "./platform-css";
import { useTranslation } from "react-i18next";

export default function Platform() {
  const { t } = useTranslation();

  return (
    <S.FirstWrapper id="main">
      <S.LeftSection>
        <S.ContentWrapper>
          <S.Content>
            {t("platform.platformShare")} <br />
            {t("platform.platformShareRewarwd")} <br />
            {t("platform.platformShareRewarwdPoint")}
            <S.BuxiSpan>{t("platform.buxi")}</S.BuxiSpan>
          </S.Content>
          <S.Tagline>{t("platform.platformTagline")}</S.Tagline>
        </S.ContentWrapper>
      </S.LeftSection>
      <S.RightSection>
        <S.MainImg src="images/bagoPhone.png" />
      </S.RightSection>
    </S.FirstWrapper>
  );
}
