import * as S from "./t2e-css";
import { useTranslation } from "react-i18next";

export default function T2E() {
  const { t } = useTranslation();

  return (
    <S.T2EWrapper>
      <S.T2ETitle>
        <S.T2EBuxiSpan>{t("t2e.buxi")}</S.T2EBuxiSpan> {t("t2e.vision")}
      </S.T2ETitle>
      <S.T2EFirstCardWrapper>
        <S.Img src="images/te2.png" />
        <S.T2EContentWrapper>
          <S.T2EBenefitContent>
            {t("t2e.platform")}
            <S.T2EContentSpan>{t("t2e.benefits")}</S.T2EContentSpan>
          </S.T2EBenefitContent>
          <S.T2ETagline>{t("t2e.paradigm")}</S.T2ETagline>
        </S.T2EContentWrapper>
      </S.T2EFirstCardWrapper>
      <S.T2ESecondCardWrapper>
        <S.T2EContentWrapper>
          <S.T2EContent>
            {t("t2e.travel")}
            <S.T2EContentSpan>{t("t2e.dao")}</S.T2EContentSpan>
          </S.T2EContent>
          <S.T2ETagline>
            {t("t2e.participants")} {t("t2e.common")} <br />
            {t("t2e.operate")}
          </S.T2ETagline>
        </S.T2EContentWrapper>
        <S.Img src="images/hiking.png" />
      </S.T2ESecondCardWrapper>
      <S.T2EThirdCardWrapper>
        <S.AirplaneImg src="images/airplane.png" />
        <S.T2EContentWrapper>
          <S.T2ESettleContent>
            <S.T2EContentSpan>{t("t2e.integrated")}</S.T2EContentSpan> <br />
            {t("t2e.settle")}
          </S.T2ESettleContent>
          <S.T2ESettleTagline>{t("t2e.consolidate")}</S.T2ESettleTagline>
        </S.T2EContentWrapper>
      </S.T2EThirdCardWrapper>
    </S.T2EWrapper>
  );
}
