import Platform from "./platform/platform";
import Blockchain from "./blockchain/blockchain";
import Exchange from "./exchange/exchange";
import T2E from "./t2e/t2e";
import Mining from "./mining/mining";
import Services from "./services/services";
import Partners from "./partners/partners";
import FAQ from "./faq/faq";
import Contact from "./contact/contact";

export default function Home() {
  return (
    <>
      <Platform />
      <Blockchain />
      <Exchange />
      <T2E />
      <Mining />
      <Services />
      <Partners />
      <FAQ />
      <Contact />
    </>
  );
}
