import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { RefObject, useEffect, useState } from "react";
import { breakPoints } from "../../../common/styles/media";

interface ScrollProps {
  topRef: RefObject<HTMLDivElement>;
}

const SlideIn = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
`;

const SlideOut = keyframes`
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
`;

const ButtonWrapper = styled.div`
  .show {
    animation: ${SlideIn} 0.5s;
    opacity: 1;
  }

  .hide {
    animation: ${SlideOut} 0.5s;
    opacity: 0;
  }
`;

const Wrapper = styled.button`
  width: 66px;
  height: 66px;
  background: #65a3ff;
  box-shadow: 1px 8px 2px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  border: none;
  :active {
    transform: translateY(15%);
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: fixed;
  bottom: 50px;
  right: 100px;
  z-index: 1;
  animation: ${SlideIn} 0.5s;
  @media ${breakPoints.mobile} {
    bottom: 30px;
    right: 25px;
    width: 44px;
    height: 44px;
    z-index: 2;
  }
`;

const Img = styled.img`
  position: relative;
  padding: 2px;
  @media ${breakPoints.mobile} {
    width: 25px;
    height: 25px;
  }
`;

export const Top = styled.label`
  color: #fff;
  @media ${breakPoints.mobile} {
    font-size: 10px;
  }
`;

export default function ScrollArrowUp({ topRef }: ScrollProps) {
  const [isShow, setIsShow] = useState(false);

  const onClickScroll = () => {
    topRef.current?.scrollIntoView();
  };

  const onScroll = () => {
    if (window.scrollY > 680) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
  }, []);

  return (
    <ButtonWrapper>
      <Wrapper onClick={onClickScroll} className={isShow ? "show" : "hide"}>
        <Img src="/images/arrow-up.png" />
        <Top>TOP</Top>
      </Wrapper>
    </ButtonWrapper>
  );
}
