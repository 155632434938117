import * as S from "./header-css";
import { useRef, useState } from "react";
import ScrollArrowUp from "../../../components/common/scrollArrowUp";
import i18n from "../../language/i18n";

export default function Header() {
  const topRef = useRef<HTMLDivElement>(null);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>("en");

  const onClickLang = (lang: string) => () => {
    i18n.changeLanguage(lang);
    setIsShow(false);
    setLanguage(lang);
  };

  const onClickMenu = () => {
    setIsShow(true);
  };

  const onClickCancel = () => {
    setIsShow(false);
  };

  const onClickNavMenu = () => {
    setIsShow(false);
  };

  return (
    <>
      <S.Wrapper ref={topRef} isShow={isShow}>
        <S.LogoWrapper>
          <S.ImgWrapper>
            {isShow ? (
              <S.MobileLogoImg src="images/mobile/lowerNavyBago.png" />
            ) : (
              <S.LogoImg src="images/lowerBago.png" />
            )}
            <S.DeadSpace />
          </S.ImgWrapper>
        </S.LogoWrapper>
        <S.NavWrapper>
          <S.MainLabel href="#main">Main</S.MainLabel>
          <S.AboutLabel href="#about">About Us</S.AboutLabel>
          <S.PartnersLabel href="#partners">Partners</S.PartnersLabel>
          <S.FaqLabel href="#faq">FAQ</S.FaqLabel>
          <S.ContactLabel href="#contact">Contact</S.ContactLabel>
          <S.WhitepaperLabel href={`/pdf/whitepaper.${language}.pdf`} download>
            White paper
          </S.WhitepaperLabel>
          <S.LanguageWrapper>
            <S.EngLabel onClick={onClickLang("en")}>ENG</S.EngLabel>
            <S.KorLabel onClick={onClickLang("ko")}>KOR</S.KorLabel>
          </S.LanguageWrapper>
        </S.NavWrapper>
        <ScrollArrowUp topRef={topRef} />
        {isShow && (
          <S.MobileXImg
            src="images/mobile/navyMenu.png"
            onClick={onClickCancel}
          />
        )}
        <S.Menu src="images/mobile/menu.png" onClick={onClickMenu} />
      </S.Wrapper>
      {isShow && (
        <S.MobileMenuWrapper>
          <S.MobileNavWrapper>
            <S.MobileMain href="#main" onClick={onClickNavMenu}>
              MAIN
            </S.MobileMain>
            <S.MobileAbout href="#about" onClick={onClickNavMenu}>
              ABOUT US
            </S.MobileAbout>
            <S.MobilePartners href="#partners" onClick={onClickNavMenu}>
              PARTNERS
            </S.MobilePartners>
            <S.MobileFaq href="#faq" onClick={onClickNavMenu}>
              FAQ
            </S.MobileFaq>
            <S.MobileContact href="#contact" onClick={onClickNavMenu}>
              CONTACT
            </S.MobileContact>
            <S.MobileWhitePaper
              href={`/pdf/whitepaper.${language}.pdf`}
              download
            >
              WHITE PAPER
            </S.MobileWhitePaper>
          </S.MobileNavWrapper>
          <S.MobileSelectWrapper>
            <S.Globe src="images/mobile/global.png" />
            <div>SELECT LANGUAGES</div>
          </S.MobileSelectWrapper>
          <S.MobileLangWrapper>
            <S.EngLangLabel onClick={onClickLang("en")}>English</S.EngLangLabel>
            <S.KorLangLabel onClick={onClickLang("ko")}>한국어</S.KorLangLabel>
          </S.MobileLangWrapper>
        </S.MobileMenuWrapper>
      )}
    </>
  );
}
