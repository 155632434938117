import { MouseEvent, useState } from "react";
import * as S from "./footer-css";
import axios from "axios";
import { Modal } from "antd";

export default function Footer() {
  const [docs, setDocs] = useState<string>("");

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const onClickDocs = async (event: MouseEvent<HTMLAnchorElement>) => {
    try {
      const result = await axios
        .get(`docs/${event.currentTarget.id}.txt`)
        .then((response) => response.data);
      setDocs(result);
    } catch (error) {
      if (error instanceof Error)
        console.log("terms text error:", error.message);
    }
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <S.Wrapper>
      <S.Line />
      <Modal
        title="Service Terms"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        centered
      >
        <S.Terms>{docs}</S.Terms>
      </Modal>
      <S.NavWrapper>
        <S.ImgWrapper>
          <S.LogoImg src="images/lowerBago.png" />
        </S.ImgWrapper>
        <S.ContentWrapper>
          <S.TermsLabel id="terms" onClick={onClickDocs}>
            Terms
          </S.TermsLabel>
          <S.PrivacyLabel id="privacy" onClick={onClickDocs}>
            Privacy
          </S.PrivacyLabel>
        </S.ContentWrapper>
        <S.SocialWrapper>
          <S.NaverWrapper
            href="https://blog.naver.com/buxikorea_blog"
            target="_blank"
            rel="noopener noreferrer"
          >
            <S.NaverLogo src="images/naver.png" />
          </S.NaverWrapper>
          <S.FacebookWrapper
            href="https://www.facebook.com/buxikorea"
            target="_blank"
            rel="noopener noreferrer"
          >
            <S.FaceLogo src="images/facebook.png" />
          </S.FacebookWrapper>
          <S.InstaWrapper
            href="https://www.instagram.com/buxikorea"
            target="_blank"
            rel="noopener noreferrer"
          >
            <S.InstaLogo src="images/instagram.png" />
          </S.InstaWrapper>
        </S.SocialWrapper>
      </S.NavWrapper>
      <S.ReservedLabel>© 2023 bago. All Rights Reserved.</S.ReservedLabel>
    </S.Wrapper>
  );
}
