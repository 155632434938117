import * as S from "./partners-css";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";

export default function Partners() {
  const { t } = useTranslation();

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
    style: {
      width: "80%",
      height: "180px",
      display: "flex",
      alignItems: "center",
    },
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          speed: 2000,
          autoplaySpeed: 2000,
          cssEase: "easing",
          dots: false,
          pauseOnHover: false,
          style: {
            width: "80%",
            height: "130px",
          },
        },
      },
    ],
  };

  const secondSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: -1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
    style: {
      width: "80%",
      height: "180px",
      display: "flex",
      alignItems: "center",
    },
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          speed: 2000,
          autoplaySpeed: 2000,
          cssEase: "easing",
          dots: false,
          pauseOnHover: false,
          style: {
            width: "80%",
            height: "120px",
          },
        },
      },
    ],
  };

  return (
    <S.PartnersWrapper id="partners">
      <S.PartnersTitle>
        <S.PartnersBuxiSpan>bago</S.PartnersBuxiSpan> {t("partners.partner")}
      </S.PartnersTitle>
      <S.PartnersTagline>{t("partners.intro")}</S.PartnersTagline>
      <Slider {...settings}>
        <div>
          <S.BuxiImg src="images/partners/buxi.png" />
        </div>
        <div>
          <S.AnswerImg src="images/partners/answer.png" />
        </div>
        <div>
          <S.LotteCardImg src="images/partners/lottecard.png" />
        </div>
        <div>
          <S.VisaImg src="images/partners/visa.png" />
        </div>
        <div>
          <S.NaverImg src="images/partners/naverlogo.png" />
        </div>
        <div>
          <S.DutyFree src="images/partners/lottedutyfree.png" />
        </div>
        <div>
          <S.LotteRentalImg src="images/partners/lotterental.png" />
        </div>
        <div>
          <S.Kkday src="images/partners/kkday.png" />
        </div>
        <div>
          <S.Hyundai src="images/partners/hyundai.png" />
        </div>
        <div>
          <S.Asiana src="images/partners/asiana.png" />
        </div>
      </Slider>
      {/* ================================================ */}
      <Slider {...secondSettings}>
        <div>
          <S.KdImg src="images/partners/kd.png" />
        </div>
        <div>
          <S.Aero src="images/partners/aero.png" />
        </div>
        <div>
          <S.ZetcabImg src="images/partners/zetcab.png" />
        </div>
        <div>
          <S.PaymentWall src="images/partners/paymentwall.png" />
        </div>
        <div>
          <S.LPoint src="images/partners/lpoint.png" />
        </div>
        <div>
          <S.VcarImg src="images/partners/vcar.png" />
        </div>
        <div>
          <S.AspireImg src="images/partners/aspire.png" />
        </div>
        <div>
          <S.ForParentsImg src="images/partners/forparents.png" />
        </div>
        <div>
          <S.NkgenImg src="images/partners/nkgen.png" />
        </div>
        <div>
          <S.MkImg src="images/partners/mk.png" />
        </div>
        <div>
          <S.Shinyoung src="images/partners/shinyoung.png" />
        </div>
      </Slider>
    </S.PartnersWrapper>
  );
}
