import * as S from "./contact-css";
import { ChangeEvent, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./contact.validate";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";

interface Inputs {
  name?: string;
  email?: string;
  message?: string;
}

export default function Contact() {
  const { t } = useTranslation();

  const form = useRef<any>(null);

  const { register, handleSubmit, formState } = useForm<Inputs>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    message: "",
  });

  const onChangeInputs = (event: ChangeEvent<HTMLInputElement>) => {
    setInputs({
      ...inputs,
      [event.target.name]: event.target.value,
    });
  };

  const onClickSend = () => {
    const { name } = inputs;
    try {
      emailjs
        .sendForm(
          // String(process.env.REACT_APP_SERVICE_ID),
          // String(process.env.REACT_APP_TEMPLATE_ID),
          "buxi-bux",
          "buxi-bux",
          form.current,
          // process.env.REACT_APP_EMAIL_KEY,
          "CyhTZAOmFAYYekB04"
        )
        .then((response) => {
          console.log(response);
          alert(`Thank you for your email, ${name}`);
        });
    } catch (error) {
      if (error instanceof Error) console.log("email error:", error.message);
      alert("Failed to send an email");
    }
    setInputs({
      name: "",
      email: "",
      message: "",
    });
  };

  return (
    <form onSubmit={handleSubmit(onClickSend)} ref={form}>
      <S.ContactWrapper id="contact">
        <S.Title>{t("contact.title")}</S.Title>
        <S.Tagline>{t("contact.question")}</S.Tagline>
        <S.FormWrapper>
          <S.NameInput
            placeholder={String(t("contact.name"))}
            {...register("name", { onChange: onChangeInputs })}
            value={inputs.name}
          />
          <S.ErrorMessage>{formState.errors.name?.message}</S.ErrorMessage>
          <S.EmailInput
            placeholder={String(t("contact.email"))}
            {...register("email", { onChange: onChangeInputs })}
            value={inputs.email}
          />
          <S.ErrorMessage>{formState.errors.email?.message}</S.ErrorMessage>
          <S.MessageInput
            placeholder={String(t("contact.message"))}
            {...register("message", { onChange: onChangeInputs })}
            value={inputs.message}
          />
          <S.ErrorMessage>{formState.errors.message?.message}</S.ErrorMessage>
          <S.SendButton>{t("contact.send")}</S.SendButton>
        </S.FormWrapper>
      </S.ContactWrapper>
    </form>
  );
}
