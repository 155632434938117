import { ReactNode } from "react";
import styled from "@emotion/styled";
import Header from "../layouts/header/header";
import Footer from "../layouts/footer/footer";

interface LayoutProps {
  children: ReactNode;
}

const Wrapper = styled.div``;

const Body = styled.div``;

export default function Layout(props: LayoutProps) {
  return (
    <Wrapper>
      <Header />
      <Body>{props.children}</Body>
      <Footer />
    </Wrapper>
  );
}
